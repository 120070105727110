<template>
  <div>
    <span 
      v-if="label" 
      class="base-label default"
    >
      {{ label }}
    </span>
    <div
      v-if="editor"
      class="editor"
    >
      <bubble-menu
        v-if="!editor.isActive('formula')"
        v-show="editor.isActive('custom-image')"
        class="bubble-menu"
        :tippy-options="{ animation: false }"
        :editor="editor"
      >
        <button
          :class="{'is-active': editor.isActive('custom-image', {size: 'small'})}"
          @click="editor.chain().focus().setImage({ size: 'small' }).run()"
        >
          Small
        </button>
        <button
          :class="{'is-active': editor.isActive('custom-image', { size: 'medium' })}"
          @click="editor.chain().focus().setImage({ size: 'medium' }).run()"
        >
          Medium
        </button>
        <button
          :class="{'is-active': editor.isActive('custom-image', { size: 'large'})}"
          @click="editor.chain().focus().setImage({ size: 'large' }).run()"
        >
          Large
        </button>
        <span style="color: #aaa">|</span>
        <button
          :class="{ 'is-active': editor.isActive('custom-image', { float: 'left'})}"
          @click="editor.chain().focus().setImage({ float: 'left' }).run()"
        >
          Left
        </button>
        <button
          :class="{'is-active': editor.isActive('custom-image', {float: 'none'})}"
          @click="editor.chain().focus().setImage({ float: 'none' }).run()"
        >
          No float
        </button>
        <button
          :class="{'is-active': editor.isActive('custom-image', { float: 'right'})}"
          @click="editor.chain().focus().setImage({ float: 'right' }).run()"
        >
          Right
        </button>
        <span style="color: #aaa">|</span>
        <button @click="addImage">
          Change
        </button>
      </bubble-menu>

      <div v-if="!input">
        <div class="is-flex editor-options">
          <b-tooltip label="Bold">
            <b-button
              size="is-small"
              :type="editor.isActive('bold') ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleBold().run()"
            >
              <b-icon icon="format-bold" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Italic">
            <b-button
              size="is-small"
              :type="editor.isActive('italic') ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleItalic().run()"
            >
              <b-icon icon="format-italic" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Strikethrough">
            <b-button
              size="is-small"
              :type="editor.isActive('strike') ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleStrike().run()"
            >
              <b-icon icon="format-strikethrough" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Align text left">
            <b-button
              size="is-small"
              :type="editor.isActive({ textAlign: 'left' }) ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().setTextAlign('left').run()"
            >
              <b-icon icon="format-align-left" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Align text center">
            <b-button
              size="is-small"
              :type="editor.isActive({ textAlign: 'center' }) ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().setTextAlign('center').run()"
            >
              <b-icon icon="format-align-center" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Align text right">
            <b-button
              size="is-small"
              :type="editor.isActive({ textAlign: 'right' }) ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().setTextAlign('right').run()"
            >
              <b-icon icon="format-align-right" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Justify text">
            <b-button
              size="is-small"
              :type="editor.isActive({ textAlign: 'justify' }) ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().setTextAlign('justify').run()"
            >
              <b-icon icon="format-align-justify" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Inline code">
            <b-button
              size="is-small"
              :type="editor.isActive('code') ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleCode().run()"
            >
              <b-icon icon="code-tags" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Paragraph">
            <b-button
              size="is-small"
              :type="editor.isActive('paragraph') ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().setParagraph().run()"
            >
              <b-icon icon="format-paragraph" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Large header">
            <b-button
              size="is-small"
              :type="editor.isActive('heading', { level: 1 }) ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            >
              <b-icon icon="format-header-1" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Medium header">
            <b-button
              size="is-small"
              :type="editor.isActive('heading', { level: 2 }) ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            >
              <b-icon icon="format-header-2" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Small header">
            <b-button
              size="is-small"
              :type="editor.isActive('heading', { level: 3 }) ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            >
              <b-icon icon="format-header-3" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Bullet list">
            <b-button
              size="is-small"
              :type="editor.isActive('bulletList') ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleBulletList().run()"
            >
              <b-icon icon="format-list-bulleted" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Ordered list">
            <b-button
              size="is-small"
              :type="editor.isActive('orderedList') ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleOrderedList().run()"
            >
              <b-icon icon="format-list-numbered" />
            </b-button>
          </b-tooltip>

          <b-tooltip label="Image">
            <b-button
              size="is-small"
              type="is-white"
              @click="addImage()"
            >
              <b-icon icon="image" />
            </b-button>
          </b-tooltip>

          <b-tooltip label="Attach Link">
            <b-button
              size="is-small"
              :type="editor.isActive('link') ? 'is-primary' : 'is-white'"
              @click="openLinkInputModal()"
            >
              <b-icon icon="link" />
            </b-button>
            <b-button
              v-if="editor.isActive('link')"
              size="is-small"
              type="is-white"
              @click="editor.chain().focus().unsetLink().run()"
            >
              <b-icon icon="link-off" />
            </b-button>
          </b-tooltip>

          <!--    <b-button size="is-small" variant="white" @click="setUrl()">-->
          <!--      <b-icon icon="link"/>-->
          <!--    </b-button>-->
          <b-tooltip label="Quote">
            <b-button
              size="is-small"
              :type="editor.isActive('blockquote') ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleBlockquote().run()"
            >
              <b-icon icon="format-quote-open" />
            </b-button>
          </b-tooltip>

          <b-tooltip label="Code block">
            <b-button
              size="is-small"
              :type="editor.isActive('codeBlock') ? 'is-primary' : 'is-white'"
              @click="editor.chain().focus().toggleCodeBlock().run()"
            >
              <b-icon icon="code-braces-box" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Horizontal line">
            <b-button
              type="is-white"
              size="is-small"
              @click="editor.chain().focus().setHorizontalRule().run()"
            >
              <b-icon icon="view-stream-outline" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Undo">
            <b-button
              type="is-white"
              size="is-small"
              @click="editor.chain().focus().undo().run()"
            >
              <b-icon icon="undo" />
            </b-button>
          </b-tooltip>
          <b-tooltip label="Redo">
            <b-button
              type="is-white"
              size="is-small"
              @click="editor.chain().focus().redo().run()"
            >
              <b-icon icon="redo" />
            </b-button>
          </b-tooltip>
        </div>

        <editor-content
          :editor="editor"
          :class="classes"
        />
      </div>
    </div>
  </div>
</template>

<script>
// libs
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
// others
import CustomImage from './extensions/custom-image';

export default {
    name: 'Editor',
    components: {
        EditorContent,
        BubbleMenu
    },
    props: {
        input: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: null
        },
        values: {
            type: Object,
            required: true
        },
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            editor: null,
            classes: ''
        };
    },

    computed: {
        value () {
            return this.values[this.field.id];
        }
    },

    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value;
            if (isSame) {
                return;
            }

            this.editor.commands.setContent(this.value, false);
        },
    },

    mounted() {

        this.setClasses();
        this.createEditor();
        this.$nextTick(() => {
            if (this.value) {
                this.editor.commands.setContent(this.value);
            }
        });
    },

    beforeDestroy() {
        this.editor.destroy();
    },

    methods: {
        createEditor(){
            this.editor = new Editor({
                extensions: [
                    StarterKit,
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    }),
                    Highlight, Document,
                    Paragraph,
                    Text,
                    Image,
                    Placeholder.configure({
                        placeholder: this.field.properties.basic?.placeholder
                    }),
                    Link.configure({
                        autolink: false,
                        linkOnPaste: false,
                    }),
                    CustomImage.configure({
                        HTMLAttributes: {
                            class: 'custom-image'
                        }
                    })
                ],
                content: this.value,
                editable: this.disabled !== 'disabled',
                disablePasteRules:true,
                onUpdate: () => {
                    this.$emit('input', this.editor.getHTML());
                    this.$emit('update', {
                        value: this.editor.getHTML() 
                    });
                },
            });
        },
        addImage() {
            const url = window.prompt('Image URL');
            if (url) {
                this.editor.chain().focus().setImage({src: url}).run();
            }
        },
        setClasses() {
            if (this.input) {
                this.classes += 'tiptap-editor-input';
            } else {
                this.classes += 'tiptap-editor-textarea';
            }



            if (this.disabled === 'disabled') {
                this.classes += ' tiptap-editor-disabled';
            }

        },
        setLink(url) {
            // cancelled
            if (url === null) {
                return;
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run();
            } else {
                // update link
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: url })
                    .run();
            }
        },

        openLinkInputModal () {
            const initialUrl = this.editor.getAttributes('link').href;
            const newUrl = prompt('Enter URL', initialUrl);
            this.setLink(newUrl);
        }
    }
};
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }
}
.tiptap-editor-textarea {
  min-height: 200px !important;
  border-radius: 2px;
  font-size: 0.75rem;
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  max-width: 100%;
  width: 100%;
  background-color: white;
  color: #363636;
  border: 1px solid #dbdbdb;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  position: relative;
  vertical-align: top;
}

.tiptap-editor-input {
  border-radius: 8px;
  font-weight: 400;
  font-size: 13px!important;
  line-height: 18px;
  color: #222222;
  padding: 6px 14px;

  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  max-width: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #dbdbdb;
  justify-content: flex-start;
  position: relative;
  vertical-align: top;
}

.tiptap-editor-disabled {
  background: #dedede;
  p {
    color: #777777;
  }
}

.tiptap-editor-input {
  .ProseMirror {
    overflow-x: hidden;
  }
}

.ProseMirror-focused {
  outline: none !important;
}

.ProseMirror {
  font-size: 13px !important;
  height: 100% !important;
  overflow-y: auto;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }

  h1 {
    font-size: 2rem;
    font-weight: bolder;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.3rem;
  }

  blockquote {
    display: block;
    padding-left: 1rem;
    margin: 1rem 0;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
  }

  ul {
    list-style-type: disc;
  }

  ol {
    padding-left: 20px !important;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #FAF594;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  .custom-image-small {
    max-width: 200px;
  }

  .custom-image-medium {
    max-width: 500px;
  }

  .custom-image-large {
    max-width: 100%;
  }

  .custom-image-float-none {
    float: none;
  }

  .custom-image-float-left {
    float: left;
  }

  .custom-image-float-right {
    float: right;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.editor-options {
  flex-wrap: wrap;
  .b-tooltip {
    display: block;
  }
  .button {
    margin: 0;
    width: 100%;
  }
}
/* Placeholder (at the top) */
.tiptap p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
</style>